<template>
  <div class="table_container">
<!--    <add-domain-modal ref="add_domain_modal"></add-domain-modal>-->
    <table class="table   list-content">
      <thead class=" ">
      <tr>
        <th>Connectivity</th>
        <th class="" style="width: 230px;">URL</th>
        <th class="" style="width: 230px;">404 Redirect URL</th>
        <th class="" style="width: 200px;">Created</th>
        <th class="" style="width: 200px;">Set As Default</th>
        <th class="" style="width: 200px;">Use HTTPS</th>
        <th style="width: 200px;text-align: right;" v-if="checkNotClient()">Actions</th>
      </tr>
      </thead>
      <tbody class="list-content-body">
      <template v-if="getCustomDomainsLoader.custom_domain_loader">
        <tr>
          <td colspan="9">
            <p style="padding: 10px;">
              <clip-loader
                :size="'22px'"
                :color="'#3988fe'"
              ></clip-loader>
            </p>

          </td>
        </tr>
      </template>
      <template v-else-if="getDomains.items.length > 0">
        <tr v-for="(domain,index) in getDomains.items ">
          <td>
            <div v-tooltip="domainStatusMessage(domain.connectivity)" class="campaign_status"
                 :class="{'active_campaign':
                     domain.connectivity, 'disable_campaign': !domain.connectivity}"></div>
          </td>
          <td>
            <div class="url">
              {{ trimUrl(domain.url) }}
            </div>
          </td>
          <td>
            <div class="url" v-if="domain.redirect_url">
              {{ domain.redirect_url }}
            </div>

            <div class="ur light_grey" v-else>
              N/A
            </div>
          </td>
          <td class="created_at">
            {{ domain.created_at | relativeCaptilize }}
          </td>
          <td>
            <label class="switch"
                   :class="{'switch_disabled': !domain.connectivity}"
                   :for="'default_domain_'+index">
              <input :disabled="!domain.connectivity"
                     type="checkbox" :id="'default_domain_'+index" :checked="domain.default"
                     @click="setDefaultDomainStatus(domain)">
              <div class="slider round"></div>
            </label>
          </td>

          <!--<td class="ssl_connected">-->
          <!--<div v-tooltip="domainSslMessage(domain.ssl_connected)" class="campaign_status"-->
          <!--:class="{'active_campaign': domain.ssl_connected, 'disable_campaign': !domain.ssl_connected}"></div>-->
          <!--</td>-->

          <td>
            <label class="switch" :class="{'switch_disabled': !domain.connectivity}"
                   :for="'ssl_domain_'+index">
              <input :disabled="!domain.connectivity"
                     type="checkbox" :id="'ssl_domain_'+index"
                     :checked="domain.useHttps"
                     @click="setDomainHTTPSMethod(domain)">
              <!--@click="useHttps(domain._id,index,domain.connectivity,domain.ssl_connected,domain.useHttps)">-->

              <div class="slider round"></div>
            </label>
          </td>

          <td class="table-action " v-if="checkNotClient()">

            <i v-tooltip="'Check Connectivity'" class="far fa-plug "
               v-if="!domain.showLoader"
               @click="checkConnectivity(domain)" aria-hidden="true"></i>

            <clip-loader class="d-inline-block" :size="'16px'" :color="'#3988fe'"
                         style="vertical-align: middle"
                         v-tooltip="'Checking Connectivity...'"
                         v-if="domain.showLoader"></clip-loader>
            <i v-tooltip="'DNS Records'" class="far fa-globe "
               @click.prevent="showDNSRecords(domain.url)" aria-hidden="true"></i>
            <i v-tooltip="'Edit Domain'" class="far fa-pencil "
               @click.prevent="editDomain(domain)" aria-hidden="true"></i>
            <i v-tooltip="'Remove Domain'" class="far fa-trash "
               @click.prevent="deleteCustomDomain(domain)" aria-hidden="true"></i>
          </td>
        </tr>

      </template>
      <template v-else>
      <tr>
        <td colspan="7">
          <p class="text-center p-4">You have not added any custom domains yet, click on the below button to
            get
            started!</p>
        </td>
      </tr>
      </template>
      </tbody>

      <!--  <div class="col-md-12" v-if="loader === false">
            <div class="display-center txt-center" v-if="resultCount > 10">
                <pagination :clickedMethod="getPageValues" :resultCount="resultCount"
                            :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                            :totalPagesNumber="totalPagesNumber"></pagination>
            </div>
        </div>-->

    </table>
  </div>

</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { domainTypes } from '@/state/modules/mutation-types'
import AddDomainModal from './dialogs/AddDomainModal'

export default ({
  components: {
    'add-domain-modal': AddDomainModal
  },
  data () {
    return {
      isCheckDomainConnectivity: false,
      isShowModal: false
    }
  },
  async created () {
    await this.fetchDomains()
  },
  methods: {
    ...mapActions([
      'checkDomainConnectivity',
      'deleteDomain',
      'checkDNSRecordsForDomain',
      'setDomainHTTPSMethod',
      'setDefaultDomainStatus',
      'fetchDomains',
      'fetchCustomDomainLink'
    ]),
    ...mapMutations([domainTypes.SET_ADD_DOMAIN]),
    domainStatusMessage (status) {
      if (status) {
        return 'Your domain is verified and connected with Replug.io'
      }
      return 'Your domain is not verified yet'
    },
    domainSSLMessage (status) {
      if (status) {
        return 'Your domain is ssl verified and connected with Replug.io'
      }
      return 'Your domain is not ssl verified yet'
    },
    editDomain (domain) {
      // this.isShowModal = true
      // this[domainTypes.SET_ADD_DOMAIN](domain)
      // $('#customDomainModal').modal('show')
      this.$parent.showModal(true, domain)
      // setTimeout(() => {
      //   this.$bvModal.show('customDomainModal')
      // },100)
    },
    closeModal () {
      this.isShowModal = false
      setTimeout(() => {
        this.isShowModal = true
      }, 100)
    },
    async showDNSRecords (url) {
      this.checkDNSRecordsForDomain(url)
      this.$bvModal.show('dns_records_modal')
    },
    async checkConnectivity (domain) {
      domain.showLoader = true
      await this.checkDomainConnectivity(domain)
      domain.showLoader = false
    },
    deleteCustomDomain(domain){
      this.fetchCustomDomainLink(domain)
    }
  },
  computed: {
    ...mapGetters(['getDomains', 'getCustomDomainsLoader'])
  }

})
</script>
