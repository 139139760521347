<template>

  <b-modal modal-class="modal_basic customDomainModal inline_style_modal" id="customDomainModal" hide-footer
           hide-header>
    <img @click="close()" class="close_modal" src="/assets/img/cross.svg" alt="">
    <div v-on-clickaway="close">
      <div class="basic_form modal_content">

        <div class="heading">
          <h3>
            Connect Your Custom Domain
          </h3>
        </div>

        <clip-loader :color="'#0095f3'" :size="'20px'" v-if="domainLoader"></clip-loader>

        <div v-if="!domainLoader" class="basic_form clear">
          <div class="input_field" v-if="getAddDomain._id && !getAddDomain.connectivity">
            <div class="alert_box_new warning white-shadow">
              <div class="inner_box align_center">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <div class="content">
                  <p class="title">DNS not yet verified</p>
                  <p class="text"> DNS propagation can take up-to 24 hours. Please make sure you
                    have pointed the DNS records as mentioned below.</p>
                  <p class="text">We'll also send you an email once your domain is verified.</p>
                </div>
              </div>
            </div>

          </div>


          <div class="input_field d-flex align-items-center mb-3 pb-3">
            <div class="left">
              <p class="label">Domain URL </p>
            </div>
            <div class="right">
              <p class="domain_link">
                <a target="_blank" v-if="!getSiteDetails.isWhiteLabel"
                   href="https://docs.replug.io/article/164-custom-domain-setup">How
                  to add DNS records for your custom domain?</a>
              </p>
              <div class="validation_input"
                   :class="{'has-error' : validations.url || validations.validUrl || validations.urlLength}">
                <input :class="{'input-field-error' : validations.campaign}" v-model="getAddDomain.url"
                       type="url" class="input-shadow" @keyup.enter="addDomain()"
                       @blur="getAddDomain.url = focusoutConcatenation(getAddDomain.url)"
                       placeholder="Your branded domain url. i.e https://track.mywebsite.com" data-cy="c-domain">

                <span class="input-error" v-if="validations.url">{{ messages.url }}</span>
                <span class="input-error" v-else-if="validations.urlLength">{{ messages.urlLength }}</span>
                <span class="input-error" v-else-if="validations.validUrl">{{ messages.validUrl }}</span>
              </div>

            </div>
          </div>

          <div class="input_field d-flex align-items-center mb-3 pb-3">
            <div class="left">
              <p class="label">Custom 404 redirection
              </p>
              <p class="sub_label">When a user clicks on the {{ getSiteDetails.agency_name }} link that does not exist or
                contains a
                typo, instead of showing an error message the user will be
                redirected to this page e.g https://mywebsite.com/404</p>
            </div>
            <div class="right">
              <div class="validation_input"
                   :class="{'has-error' : validations.validRedirectUrl || validations.validRedirectUrlLength}">
                <input v-model="getAddDomain.redirect_url" type="url"
                       placeholder="Enter 404 Redirect URL i.e https://mywebsite.com/404"
                       class="input-shadow"
                       @blur="getAddDomain.redirect_url = focusoutConcatenation(getAddDomain.redirect_url)"
                       @keyup.enter="addDomain()" data-cy="c-404">
                <span class="input-error" v-if="validations.validRedirectUrlLength">{{ messages.urlLength }}</span>
                <span class="input-error" v-else-if="validations.validRedirectUrl">{{ messages.validUrl }}</span>
              </div>
            </div>
          </div>

          <div class="input_field d-flex align-items-center mb-3 pb-3">
            <div class="left"><p class="label">Branded domain</p>
              <p class="sub_label">Users will be redirected to this URL when they visit the
                root address of your brand domain.</p>
            </div>
            <div class="right">
              <div class="validation_input"
                   :class="{'has-error' : !validations.validBrandUrl || validations.validBrandUrlLength}">
                <input v-model="getAddDomain.root_redirect" type="url"
                       placeholder="Your main web page URL i.e https://mywebsite.com"
                       class="input-shadow"
                       @blur="getAddDomain.root_redirect = focusoutConcatenation(getAddDomain.root_redirect)"
                       @keyup.enter="addDomain()" data-cy="m-domain">
                <span class="input-error" v-if="validations.validBrandUrlLength">{{ messages.urlLength }}</span>
                <span class="input-error" v-else-if="validations.validBrandUrl">{{ messages.validUrl }}</span>
              </div>
            </div>
          </div>

          <div class="input_field align_top pb-0">
            <div class="left">
              <p class="label">DNS configuration</p>
              <p class="sub_label">(Expert only)</p>
            </div>
            <div class="right">
              <div class="content">
                <p class="heading mb-0">Mandatory</p>
                <p class="desc">We recommend the following configuration that enables you to use
                  variations of your branded domain to create links through {{ getSiteDetails.agency_name }} dashboard.</p>
              </div>
              <div class="table_container">
                <table class="table list-content" style="    border: 1px solid #f0f0f3;">
                  <thead class=" ">
                  <tr>
                    <th style="text-align: center;">Record type</th>
                    <th style="text-align: center;">Hostname (or name)</th>
                    <th style="text-align: center;">Points to (or address)</th>
                  </tr>
                  </thead>

                  <tbody class="list-content-body">

                  <tr v-if="!!this.dns_config">
                    <td style="text-align: center;">CNAME</td>
                    <td style="text-align: center;">* domain name *</td>
                    <td style="text-align: center;">{{ this.dns_config.cname_address }}</td>
                  </tr>
<!--                  <tr v-if="!!this.dns_config">-->
<!--                    <td style="text-align: center;">A</td>-->
<!--                    <td style="text-align: center;">* domain name *</td>-->
<!--                    <td style="text-align: center;">{{ this.dns_config.a_address }}</td>-->
<!--                  </tr>-->
                  <tr v-if="!this.dns_config">
                    <td colspan="3">Unable to fetch DNS Configuration - Please contact Support Team.</td>
                  </tr>
                  </tbody>

                </table>
              </div>

              <div v-if="false" class="content">
                <p class="heading mb-0">Optional</p>
                <p class="desc">We recommend the following configuration that enables you to use
                  variations of your branded domain to create links through {{ getSiteDetails.agency_name }} dashboard.</p>
              </div>

            </div>
          </div>

        </div>

        <div v-if="!domainLoader" class=" btn_block text-left mt-1">

          <button :disabled="getCustomDomainsLoader.add"
                  @click.prevent="addDomain()"
                  class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">
                        <span>
                        <template v-if="!getAddDomain._id">
                            Connect
                        </template>
                        <template v-else>
                            Update
                        </template>
                        </span>

            <clip-loader v-if="getCustomDomainsLoader.add" :size="'12px'" :color="color"></clip-loader>
          </button>
          <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                  @click="close()"><span>Close</span>
          </button>

        </div>

      </div>
    </div>
  </b-modal>
</template>
<script>
import {mapGetters} from 'vuex'

import validations from '@/mixins/validations'

export default ({
  components: {},
  props: {
    dns_config: {
      required: true
    }
  },
  data () {
    return {
      validations: {
        url: false,
        validUrl: false,
        urlLength: false,
        validRedirectUrl: false,
        validRedirectUrlLength: false,
        validBrandUrl: false,
        validBrandUrlLength: false,
      },
      domainLoader: true,
      messages: {
        url: 'URL is required.',
        validUrl: 'Please enter a valid URL.',
        urlLength: 'The URL should not be greater than 500 characters.'
      }

    }
  },
  computed: {
    ...mapGetters(['getAddDomain', 'getCustomDomainsLoader'])
  },
  created () {
    setTimeout(() => {
      this.domainLoader = false
    }, 1200)
  },
  methods: {
    addDomain () {
      this.validations.url = this.requiredCheck(this.getAddDomain.url)
      this.validations.validUrl = !this.isValidURL(this.getAddDomain.url)

      if (this.getAddDomain.redirect_url && this.getAddDomain.redirect_url.length > 0) {
        this.validations.validRedirectUrl = !this.isValidURL(this.getAddDomain.redirect_url)
      } else {
        this.validations.validRedirectUrl = false
      }

      if (this.getAddDomain.root_redirect && this.getAddDomain.root_redirect.length > 0) {
        this.validations.validBrandUrl = !this.isValidURL(this.getAddDomain.root_redirect)
      } else {
        this.validations.validBrandUrl = false
      }

      let isDomainExists = false
      let blockDomainList = ['replug.link', 'rplg.co', 'replug.io']

      blockDomainList.forEach(item => {
        if(this.getAddDomain.url.includes(item)) {
          isDomainExists = true
        }
      })

      if(isDomainExists) {
        this.$store.dispatch('toastNotification', {
          message: `Domain url can not be ${this.getAddDomain.url}.`,
          type: 'error'
        })
        return
      }

      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.$store.dispatch('storeDomain')
        this.close()
      }
    },
    close () {
      this.$parent.closeModal()
      this.$bvModal.hide('customDomainModal')
    }
  },
  watch: {
    'getAddDomain.url' (value) {
      if (value && value.length > 8) {
        this.validations.url = this.requiredCheck(this.getAddDomain.url)
        this.getAddDomain.url = this.urlConcatenation(this.getAddDomain.url)
        this.validations.urlLength = !this.maxLength(this.getAddDomain.url, 500)
        this.validations.validUrl = !this.isValidURL(this.getAddDomain.url)
      }
    },
    'getAddDomain.redirect_url' (value) {
      if (value && value.length > 8) {
        this.getAddDomain.redirect_url = this.urlConcatenation(this.getAddDomain.redirect_url)
        this.validations.validRedirectUrlLength = !this.maxLength(this.getAddDomain.redirect_url, 500)
        this.validations.validRedirectUrl = !this.isValidURL(this.getAddDomain.redirect_url)
      }
    },
    'getAddDomain.root_redirect' (value) {
      if (value && value.length > 8) {
        this.getAddDomain.root_redirect = this.urlConcatenation(this.getAddDomain.root_redirect)
        this.validations.validBrandUrlLength = !this.maxLength(this.getAddDomain.root_redirect, 500)
        this.validations.validBrandUrl = !this.isValidURL(this.getAddDomain.root_redirect)
      }
    }
  }
})
</script>
