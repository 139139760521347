<template>

  <b-modal modal-class="modal_basic dns_records_modal inline_style_modal" id="dns_records_modal" hide-footer
           hide-header>
    <img @click="$bvModal.hide('dns_records_modal')" class="close_modal" src="/assets/img/cross.svg" alt="">

    <div class="basic_form modal_content">

      <div class="heading">
        <h3>
          DNS Records List
        </h3>
      </div>

      <div class="basic_form clear">
        <div class="input_field align_top" style="width:100%;">


          <div class="table_container" style="width:100%;">
            <table class="table list-content mb-0" style="    border: 1px solid #f0f0f3;">
              <thead class=" ">
              <tr>
                <th style="text-align: center;">Record type</th>
                <th style="text-align: center;">Record class</th>
                <th style="text-align: center;">Hostname (or name)</th>
                <th style="text-align: center;">Points to (or address)</th>
              </tr>
              </thead>

              <tbody class="list-content-body">

              <tr v-if="getCustomDomainsLoader.get_records">
                <td colspan="4">
                  <clip-loader class="d-inline-block" :size="'16px'" :color="'#3988fe'"
                               style="vertical-align: middle"></clip-loader>
                </td>
              </tr>
              <tr v-if="!getCustomDomainsLoader.get_records && getDNSRecords.length === 0">
                <td colspan="4" class="text-center">
                  No DNS records found.
                </td>
              </tr>

              <tr v-for="record in getDNSRecords">
                <td style="text-align: center;">
                  {{ record.type }}
                </td>
                <td style="text-align: center;">
                  {{ record.class }}
                </td>
                <td style="text-align: center;">
                  {{ record.host }}
                </td>
                <td style="text-align: center;" v-if="record.target">
                  {{ record.target }}
                </td>
                <td style="text-align: center;" v-else-if="record.ip">
                  {{ record.ip }}
                </td>
              </tr>
              </tbody>

            </table>
          </div>


        </div>

      </div>

    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'

export default ({
  props: {
    records: {}
  },
  computed: {
    ...mapGetters(['getDNSRecords', 'getCustomDomainsLoader'])
  }

})
</script>
