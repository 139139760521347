<template>
  <div class="setting_form clear">
    <dns-records-modal></dns-records-modal>
    <add-domain-modal v-bind:dns_config="dns_config" ref="add_domain_modal" v-if="isShowModal"></add-domain-modal>

    <div class="white_box  ">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Custom Domain</h2>

          </div>
          <div class="right ml-auto">

            <button @click.prevent="showModal()"
                    :disabled="!getDomains.addButtonStatus"
                    :class="{'disabled':!getDomains.addButtonStatus}"
                    class="btn---cta btn-blue with-shadow btn-round   btn-bold">
              <span>Add Domain</span>
              <span class="icon_right">
                               <i
                                 class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                            </span>
            </button>

          </div>

        </div>
        <div class="box_content">
          <domains-list></domains-list>
          <guided-tour v-if="getGuidedTour.step12"></guided-tour>

        </div>

      </div>
    </div>

    <!--<div class="billing_box">-->
    <!--<div class="heading">-->
    <!--<h3>Custom Domain</h3>-->
    <!--</div>-->
    <!--<div class="table_right" v-if="getDomains.items.length > 0">-->
    <!--<div>-->
    <!--<button @click.prevent="showCustomDomainModal()"-->
    <!--:disabled="!getDomains.addButtonStatus" :class="{'disabled':!getDomains.addButtonStatus}">-->
    <!--<i class="fa fa-plus" aria-hidden="true"></i> Add Domain-->
    <!--</button>-->
    <!--</div>-->
    <!--<template>-->
    <!--<domains-list></domains-list>-->
    <!--</template>-->
    <!--</div>-->
    <!--&lt;!&ndash; <template v-if="loader">-->
    <!--<clip-loader :size="size" :color="color"></clip-loader>-->
    <!--</template>&ndash;&gt;-->
    <!--<div class="list_plan" v-else-if="getDomains.items.length == 0">-->
    <!--<p class="text-center">You have not added any custom domains yet, click on the below button to get-->
    <!--started!</p>-->
    <!--<div class="custom_domain text-center align_center">-->
    <!--<button class="btn&#45;&#45;cta" @click.prevent="showCustomDomainModal()"-->
    <!--:disabled="!getDomains.addButtonStatus" :class="{'disabled':!getDomains.addButtonStatus}">-->
    <!--Add Custom Domain-->
    <!--</button>-->
    <!--</div>-->

    <!--<guided-tour v-if="getGuidedTour.step12"></guided-tour>-->
    <!--</div>-->

    <!--</div>-->
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'
import DNSRecordsModal from './dialogs/DNSRecordsModal'
import AddDomainModal from './dialogs/AddDomainModal'
import DomainsList from './DomainsList'

import GuidedTour from '@/views/guided-tour/GuidedTour.vue'
import { domainTypes } from '@/state/modules/mutation-types'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'

export default ({
  components: {
    GuidedTour,
    'dns-records-modal': DNSRecordsModal,
    'add-domain-modal': AddDomainModal,
    'domains-list': DomainsList
  },
  data () {
    return {
      size: '17px',
      color: DARK_PURPLE_LOADER_COLOR,
      loader: true,
      isShowModal: false,
      dns_config: {},
      connectLoader: false
    }
  },
  async created () {
    this.getSiteDetails
    this.dns_config = await this.fetchDNSConfig();
  },
  methods: {
    ...mapActions(['setRequiredReferral', 'setLinksFetchStatus', 'fetchDNSConfig']),
    ...mapMutations([domainTypes.RESET_ADD_DOMAIN]),
    showCustomDomainModal (isEdit, domain) {
      if (!isEdit && this.getSubscription.limits &&
        this.getDomains.items.length >= this.getSubscription.limits.custom_domains_limit) {
        setTimeout(() => {
          this.$bvModal.show('upgrade-subscription-modal')
        }, 100)
        this.alertMessage('You have reached maximum limit of your subscription plan, please upgrade your plan to unlock more features.', 'error')
        return false
      }
      if (isEdit) {
        this.$store.commit(domainTypes.SET_ADD_DOMAIN, JSON.parse(JSON.stringify(domain)))
      } else {
        this.$store.commit(domainTypes.RESET_ADD_DOMAIN, null)
      }
      this.$refs.add_domain_modal.validations = { url: false }
      // $('#customDomainModal').modal('show')

      this.$bvModal.show('customDomainModal')

    },
    showModal (isEdit = false, domain = null) {
      this.isShowModal = true

      setTimeout(() => {
        this.showCustomDomainModal(isEdit, domain)
      }, 100)
    },
    closeModal () {
      this.isShowModal = false
      setTimeout(() => {
        this.isShowModal = true
      }, 100)
    }

  },
  computed: {
    ...mapGetters(['getGuidedTour', 'getProfile', 'getSubscription', 'getDomains'])
  }

})
</script>
